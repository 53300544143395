<template>
  <div class="flex">
    <DatePicker
      v-model="date"
      mode="dateTime"
      :min-date="new Date()"
      :minuteIncrement="5"
      :timezone="doTimezoneSelect ? timezone.id : ''"
      data-testid="date-select"
    >
      <template v-slot="{ inputValue, inputEvents }">
        <div>
          <p class="text-sm-2 text-grey-light font-medium mb-0.4 relative">
            {{ label }}
          </p>
          <div class="flex">
            <input
              :value="inputValue"
              v-on="inputEvents"
              :disabled="isDisabled"
              class="w-full outline-none placeholder-grey-light-2 font-medium text-md text-grey-dark-1 px-2 py-1 border-grey-light-1 border rounded focus:border-blue-dark-1 disabled:text-grey-light disabled:placeholder-grey-light-1 disabled:border-grey-light-1 disabled:bg-white"
            />
            <img src="@/assets/img/icons/date.svg" class="relative right-4" />
          </div>
        </div>
      </template>
    </DatePicker>
    <Select
      v-if="doTimezoneSelect"
      label="Timezone"
      :options="timezoneOptions"
      :value="timezone"
      :preselectFirst="true"
      :onSelect="(value) => (timezone = value)"
      class="w-1/3"
      :disabled="isDisabled"
      data-testid="timezone-select"
    />
  </div>
</template>

<script>
import Vue from 'vue';
import DatePicker from 'v-calendar/lib/components/date-picker.umd';

Vue.component('DatePicker', DatePicker);

const timezoneOptions = [
  { label: '(UTC-11:00) Coordinated Universal Time-11', id: 'Pacific/Niue' },
  { label: '(UTC-10:00) Hawaii', id: 'US/Hawaii' },
  { label: '(UTC-09:00) Alaska', id: 'America/Anchorage' },
  { label: '(UTC-08:00) Baja California', id: 'America/Los_Angeles' },
  { label: '(UTC-07:00) Mountain Time (US & Canada)', id: 'America/Boise' },
  { label: '(UTC-06:00) Central Time (US & Canada)', id: 'America/Chicago' },
  { label: '(UTC-05:00) Eastern Time (US & Canada)', id: 'America/New_York' },
  { label: '(UTC-04:00) Georgetown, La Paz, Manaus, San Juan', id: 'America/Aruba' },
  { label: '(UTC-03:00) Buenos Aires', id: 'America/Buenos_Aires' },
  { label: '(UTC-02:00) Coordinated Universal Time-02', id: 'Brazil/DeNoronha' },
  { label: '(UTC-01:00) Azores', id: 'Atlantic/Azores' },
  { label: '(UTC) Coordinated Universal Time', id: 'UTC' },
  { label: '(UTC+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna', id: 'Europe/Amsterdam' },
  { label: '(UTC+02:00) Athens, Bucharests', id: 'Europe/Athens' },
  { label: '(UTC+03:00) Moscow, St. Petersburg, Volgograd, Minsk', id: 'Europe/Moscow' },
  { label: '(UTC+04:00) Port Louis', id: 'Indian/Mahe' },
  { label: '(UTC+05:00) Ashgabat, Tashkent', id: 'Asia/Ashgabat' },
  { label: '(UTC+06:00) Dhaka', id: 'Asia/Dhaka' },
  { label: '(UTC+07:00) Bangkok, Hanoi, Jakarta', id: 'Asia/Bangkok' },
  { label: '(UTC+08:00) Beijing, Chongqing, Hong Kong, Urumqi', id: 'Asia/Hong_Kong' },
  { label: '(UTC+09:00) Seoul', id: 'Asia/Pyongyang' },
  { label: '(UTC+10:00) Canberra, Melbourne, Sydney', id: 'Australia/Sydney' },
  { label: '(UTC+12:00) Magadan', id: 'Asia/Magadan' },
];

export default {
  name: 'DateTimePicker',
  props: {
    label: { type: String, default: '' },
    doTimezoneSelect: { type: Boolean, default: true },
    isDisabled: { type: Boolean, default: false },
  },
  data: () => ({
    date: new Date(),
    timezone: { label: '', id: '' },
    timezoneOptions,
  }),
};
</script>
